import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Btn = styled.button`
    background-color: ${({ theme, alternate, reverse }) => reverse ? 
                                                theme.palette.white : alternate ? 
                                                theme.palette.warmcont : 
                                                theme.palette.primary};
    font-size: ${({ theme, small }) => small ? theme.fontSize.small : theme.fontSize.md};
    color: ${({ theme, alternate, reverse }) => reverse ? 
            alternate ? 
            theme.palette.warmcont : 
            theme.palette.primary :
            theme.palette.white };
    border: ${({ theme, alternate, reverse }) => reverse ? 
            alternate ? 
            theme.palette.warmcont : 
            theme.palette.primary :
            theme.palette.white } 1px solid;
    border-radius: 4px;
    text-decoration: none;
    margin: 10px 10px 2px 10px;
    padding: 0.5em 0.5em;
    cursor: pointer;
    width: 250px;
    font-family: ${({ theme }) => theme.font.sans};
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: ${({ theme, alternate, reverse }) => reverse ? 
                            alternate ? 
                            theme.palette.warmcont : 
                            theme.palette.secondary :
                            theme.palette.white };
        color: ${({ theme, alternate, reverse }) => reverse ? 
                theme.palette.white :
                alternate ? 
                theme.palette.warmcont : 
                theme.palette.secondary
                };
        border: ${({ theme, alternate, reverse }) => reverse ? 
                theme.palette.white :
                alternate ? 
                theme.palette.warmcont : 
                theme.palette.secondary
                } 1px solid;
    }
`
const BtnLink = styled(props => <AnchorLink {...props} />)`
  text-decoration: none;
`

function LinkBtn ({ to, label, alternate, small, reverse }) {
    return (
        <BtnLink to={ to }>
            <Btn 
             small={ small } 
             alternate={ alternate } 
             reverse={ reverse }> { label } </Btn>
        </BtnLink>
    )
}

export { LinkBtn }