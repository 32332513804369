import React from "react"
import styled from "styled-components"
import ContactForm from "../ContactForm/ContactForm"
import { H2 } from "../../theme/page-elements/elements"

const FooterContainer = styled.div`
    width: 100vw;
    background-color: ${({ theme }) => theme.palette.dark};
    text-color: ${({ theme }) => theme.palette.white};
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 992px) {
        flex-direction: column;
    }
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 5px;

    @media only screen and (min-width: 992px) {
        width: 45%;
    }
`

const Attribution = styled.div`
    color: ${({ theme }) => theme.palette.light};
    position: "relative";
    bottom: ${({ bottom }) => bottom ? 0 : "none"};
`

const AttLink = styled.a`
    color: ${({ theme }) => theme.palette.warmcont};
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.palette.secondary};
        text-decoration: underline;
    }

    &:visited {
        color: ${({ theme }) => theme.palette.warmcont};
    }
`

export default function Footer() {
    return (
        <FooterContainer>
            <Container>
                <H2>Contact Us</H2>
                <ContactForm />
            </Container>
            <Container>
                <H2>Content</H2>
                <Attribution style={{paddingTop: "0.5em"}}>
                    All graphics including Equal Neohealth icons & logos, site icons and images are property of Damien Devlin
                </Attribution>
            </Container>
        </FooterContainer>
    )
}