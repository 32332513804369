import styled from 'styled-components'

const FrameBR = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  border-bottom: 25vh solid ${({ theme }) => theme.palette.warmsec};
  border-left: 100vw solid transparent;
`
const FrameTL = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  border-top: 15vh solid ${({ theme }) => theme.palette.warmcomp};
  border-right: 50vw solid transparent;
`
const FrameTR = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border-top: 30vh solid ${({ theme }) => theme.palette.warmsec};
  border-left: 70vw solid transparent;
`

const SlopeTL = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-top: 5vh solid ${({ theme }) => theme.palette.light};
  border-right: 100vw solid transparent;
`

const SlopeBL = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 5vh solid ${({ theme }) => theme.palette.light};
  border-right: 100vw solid transparent;
`

const SlopeBR = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 5vh solid ${({ theme }) => theme.palette.light};
  border-left: 100vw solid transparent;
`
const SlopeTR = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-top: 5vh solid ${({ theme }) => theme.palette.light};
  border-left: 100vw solid transparent;
`

export { FrameBR, FrameTL, FrameTR, SlopeTL, SlopeTR, SlopeBR, SlopeBL }