import styled from "styled-components"

const Block = styled.div`
  position: relative;
  background-color: ${({ alternate, theme }) => alternate ? theme.palette.light : theme.palette.white};
  margin: 0;
  padding-top: ${({ nopaddingTop }) => nopaddingTop ? 0 : "5vh"};
  padding-bottom: ${({ nopaddingBtm }) => nopaddingBtm ? 0 : "5vh"};
  min-height: ${({ hero }) => hero ? "100vh" : "auto"};
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(${({ theme, gradient, alternate }) => gradient ? alternate ? theme.palette.light : theme.palette.white : "none"} 50%, ${({ theme, gradient, alternate }) => gradient ? alternate ? theme.palette.white : theme.palette.light : "none"} 50%);
`

const FlexGroup = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ icons, reverse }) => icons ? "row" : reverse ? "column-reverse" : "column"};
  align-items: ${({ icons, alignTop }) => icons || alignTop ? "flex-start" : "center"};
  justify-content: center;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  } 
`

const SubBlock = styled.div`
  position: relative;
  background-color:  inherit;
  margin: 0;
  padding: 0 5%;
  width: ${({ wide, narrow }) => wide ? "60%" : narrow ? "30%" : "50%"};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    width: 100%;
  } 
`

const HeroImgWide = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 992px) {
    display: none;
  }
`

const HeroImgNarrow = styled.div`
  width: 100%;
  @media screen and (min-width: 992px) {
    display: none;
  }
`

export { Block, SubBlock, FlexGroup, HeroImgWide, HeroImgNarrow }