import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Img from "gatsby-image"
import { 
  LeadP,
  P
} from "./elements"

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  margin: auto;
  width: 95vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.2);
  border-radius: 15px;
  margin: 10px;
  @media screen and (min-width: 768px) {
    width: 40vw;
  }
  @media screen and (min-width: 1068px) {
    width: 25vw;
  }
  &:hover {
    animation: pulse 1s infinite;
  }
`

const IconDiv = styled.div`
  width: 20%;
  margin: 2%;
`

const TextDiv = styled.div`
  width: 80%;
  padding: 0;
  margin: 0;
`

const TagLink = styled(props => <AnchorLink {...props} />)`
  text-decoration: none;
`

const TagCard = ({ heading, text, queryRef, to }) => {
    const data = useStaticQuery(getIcons)
    const image = data[queryRef]

    return(
        <TagLink to={ to ? to : "#" }>
          <CardContainer>
            <IconDiv>
              <Img
                  fluid={image.childImageSharp.fluid}
                  style={{ height: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
              />
            </IconDiv>
            <TextDiv>
              <LeadP small>
                { heading }
              </LeadP>
              <P 
               small 
               nopadding
               style={{paddingTop: "0.25rem"}}>
                { text }
              </P>
            </TextDiv>
          </CardContainer>
        </TagLink>
    )
}

export const getIcons = graphql`
query {
  ai: file(relativePath: { eq: "artificial-intelligence.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  automation: file(relativePath: { eq: "automation.png" }) {
    childImageSharp  {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  growth: file(relativePath: { eq: "achievement.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  motivation: file(relativePath: { eq: "inspiration.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  values: file(relativePath: { eq: "values.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  credentials: file(relativePath: { eq: "credentials.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  partnership: file(relativePath: { eq: "partnership.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  eQuality: file(relativePath: { eq: "e-quality.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  analytics: file(relativePath: { eq: "analytics.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  certified: file(relativePath: { eq: "certificate.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  experienced: file(relativePath: { eq: "experience.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`

export { TagCard }