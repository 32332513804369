import React from 'react'
import styled from "styled-components"
import { Link } from 'gatsby'
import Img from "gatsby-image"

const Nav = styled.div`
  position: fixed;
  top: 0;
  width: ${props => props.width ? props.width : "100%"};
  height: ${props => props.visible ? props.height : "5rem"};
  display: flex;
  z-index: 200;
  background: ${({ theme }) => theme.palette.dark};
  overflow: ${props => props.visible ? "hidden" : "inherit"};
  font-size: ${props => props.visible ? "2em" : "1em"};
  text-align: ${props => props.visible ? "center" : "inherit"};
  line-height: ${props => props.visible ? "1.5" : "inherit"};

  &[data-active='false'] {
    background: ${({ visible, theme }) => visible ? theme.palette.dark : "transparent"};
  }
`

const NavLead = styled.div`
  width: 90vw;
  max-width: 1170px;
  margin: 0 auto;
  @media screen and (min-width: 769px) {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
`

const NavHeader = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 769px) {
    justify-content: space-between;
  }
`

const NavHeaderImg = styled(props => <Img {...props} />)`
  margin-bottom: 0.375rem;
  width: 50vw;
  max-width: 200px;
  display: ${props => props.scrolled ? "inherit" : "none"};
`

const ToggleBtn = styled.button`
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: ${({ theme }) => theme.palette.light};
  cursor: pointer;
  transition: var(--transition);
  
  &:hover {
    color: ${({ theme }) => theme.palette.warmcomp};
  }

  &:focus {
    color: ${({ theme }) => theme.palette.light};
    outline: none;
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`
const NavLinks = styled.ul`
  list-style-type: none;
  display: ${props => props.visible ? "block" : "none"};
  text-align: left;

  @media screen and (min-width: 769px) {
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 769px) {
    height: 100vh;
  }
`

const NavLinkItem = styled.li`
  @media screen and (min-width: 769px) {
    margin-right: 2rem;
  }

  @media screen and (max-width: 768px) {
    border-top: 0.1px solid;
    border-color: ${({ theme }) => theme.palette.primary};
  }
`

const NavLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.warmpri};

  &:visited {
    color: ${({ theme }) => theme.palette.warmpri};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.warmcomp};
  }

  @media screen and (min-width: 769px) {
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    padding: 0.5rem 0;
  }
`

export { Nav, NavLead, NavHeader, NavHeaderImg, ToggleBtn, NavLinks, NavLinkItem, NavLink}