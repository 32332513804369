import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FaAlignJustify, FaWindowClose } from "react-icons/fa"
import {
  Nav,
  NavLead,
  NavHeader,
  NavHeaderImg,
  ToggleBtn,
  NavLinks,
  NavLinkItem,
  NavLink
} from "./navbar-elements"

export default function Navbar({ width, height }) {
  const data = useStaticQuery(getLogo)

  // determine if page has scrolled and if the view is on mobile
  const [state, setState] = useState({
    scrolled: false,
    visible: false,
  });

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== state.scrolled) {
        setState({
          ...state,
          scrolled: !state.scrolled,
        });
      }
    };
    document.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [state.scrolled]);

  // toggle dropdown visibility
  const toggleVisibility = () => {
    setState({
      ...state,
      visible: !state.visible,
    });
  };

  return (
    <Nav 
     data-active={ state.scrolled } 
     visible={ state.visible }
     >
      <NavLead>
        <NavHeader>
          <div style={{ width: '100%' }}>
            <NavHeaderImg 
             fluid={data.logo.childImageSharp.fluid}
             scrolled={state.scrolled}/>
          </div>
          <ToggleBtn onClick={toggleVisibility}>
            {state.visible ? <FaWindowClose /> : <FaAlignJustify />}
          </ToggleBtn>
        </NavHeader>
        <NavLinks visible={ state.visible }>
          <NavLinkItem>
            <NavLink to="/">
              Home
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink to="/about">
              About
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink to="/services">
              Services
            </NavLink>
          </NavLinkItem>
        </NavLinks>
      </NavLead>
    </Nav>
  )
}

export const getLogo = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`