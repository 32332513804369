import styled from "styled-components"

const H1 = styled.h1`
color: ${({ theme, dark }) => dark ? theme.palette.dark : theme.palette.warmpri};
font-size: ${({ theme, small }) => small ? theme.fontSize.lg : theme.fontSize.xl};
`

const H2 = styled.h2`
color: ${({ theme, dark }) => dark ? theme.palette.dark : theme.palette.warmsec};
font-size: ${({ theme }) => theme.fontSize.lg};
`

const LeadP = styled.p`
color: ${({ alternate, theme }) => alternate ? theme.palette.warmcont : theme.palette.dark};
font-size: ${({ small, theme }) => small ? theme.fontSize.md : theme.fontSize.lg};
padding-top: 10px;
padding-bottom: 0;
padding-left: 0;
padding-right: 0;
margin: 0;
`

const P = styled.p`
color: ${({ alternate, theme }) => alternate ? theme.palette.secondary : theme.palette.dark};
font-size: ${({ small, theme }) => small ? theme.fontSize.base : theme.fontSize.md};
padding-left: ${({ nopadding }) => nopadding ? 0 : '5%'};
padding-right: ${({ nopadding }) => nopadding ? 0 : '5%'};
padding-top: 0;
padding-bottom: 0;
margin: 0;
@media screen and (min-width: 1068px) {
    max-width: 60vw;
}
`

export { H1, H2, LeadP, P}