import styled from "styled-components"

const Hero = styled.div`
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (orientation: landscape) {
    width: 100vw;
    min-height: 100vh;
  }
  @media (orientation: portrait) {
    width: 100vw;
    min-height: 100vh;
  }
`;

export { Hero };