import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Img from "gatsby-image"
import { theme } from '../global-style';
import {
  FiPlusCircle,
  FiMinusCircle
} from "react-icons/fi"
import { 
  LeadP,
  P
} from "./elements"

const pulse = keyframes`
  25% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.2);
  }
`

const CardContainer = styled.div`
  background-color: inherit;
  margin: auto;
  width: 40vw;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  margin: 10px;
`

const IconDiv = styled.div`
  width: 100%;
  padding: 0;
  &:hover {
    animation: ${( props ) => props.pulse ? pulse : "none"} 2s ease 1;
  }
  @media screen and (max-width: 800px) {
    animation: ${( props ) => props.pulse ? pulse : "none"} 2s ease 3;
  }
`

const TextDiv = styled.div`
  padding-top: 0.5em;
  margin: 0;
`

const TagLink = styled(props => <AnchorLink {...props} />)`
  text-decoration: none;
  width: inherit;
  height: inherit;
  display: inherit;
  max-width: inherit;
  flex-direction: inherit;
  align-items: inherit;
  justify-content: inherit;
  text-align: inherit;
  border: none;
  margin: none;
  padding: none;
`

const Tip = styled.div`
  padding: 0;
  margin: 0;
  @media screen and (min-width: 800px) {
    display: none;
  }
`

const IconListItem = ({ heading, text, queryRef, to, collapse }) => {
    const data = useStaticQuery(getIcons)
    const image = data[queryRef]
    const [showDetails, toggleDetails] = useState(false);

    return(
        <CardContainer>
          {
            to && 
            <TagLink 
              to={ to ? to : "#" }>
              <IconDiv pulse={to ? true : false}>
                <Img
                    fluid={image.childImageSharp.fluid}
                    style={{ width: '100%' }}
                    imgStyle={{ objectFit: 'contain' }}
                />
              </IconDiv>
              <LeadP small>
                <strong>{ heading }</strong>
              </LeadP>
            </TagLink>
          }

          {
            !to && 
            <IconDiv>
              <Img
                  fluid={image.childImageSharp.fluid}
                  style={{ width: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
              />
            </IconDiv>
          }
          { 
            !to &&
            <LeadP small>
              <strong>{ heading }</strong>
            </LeadP>
          }
          {
            !showDetails &&  text && collapse &&
            <FiPlusCircle
              color={ theme.palette.dark }
              size="1.5rem"
              onClick = { () => toggleDetails(true) } />
          }
          {
            showDetails && text && collapse &&
            <TextDiv>
              <P small nopadding>
                { text }
              </P>
              {
                to &&
                <Tip>
                  <em>Click icon for more details</em>
                </Tip>
              }
              <FiMinusCircle
                color={ theme.palette.dark }
                size="1.5rem" 
                onClick={ () => toggleDetails(false) }/>
            </TextDiv>
          }
          {
            !collapse &&
            <TextDiv>
              <P small nopadding>
                { text }
              </P>
            </TextDiv>
          }
        </CardContainer>
    )
}

export const getIcons = graphql`
query {
  innovate: file(relativePath: { eq: "innovate.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  insights: file(relativePath: { eq: "insights.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  automation: file(relativePath: { eq: "automation.png" }) {
    childImageSharp  {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  growth: file(relativePath: { eq: "scale.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  motivation: file(relativePath: { eq: "motivation.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  values: file(relativePath: { eq: "values.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  credentials: file(relativePath: { eq: "credentialled.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  partnership: file(relativePath: { eq: "partnership.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  eQuality: file(relativePath: { eq: "e-quality.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  analytics: file(relativePath: { eq: "analytics.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  certified: file(relativePath: { eq: "certificate.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  experienced: file(relativePath: { eq: "experience.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  ideation: file(relativePath: { eq: "ideation.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  prototype: file(relativePath: { eq: "prototype.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
}
`

export { IconListItem }