import { createGlobalStyle } from 'styled-components'

const theme = {
    palette: {
        primary: '#009392',
        secondary: '#82b8bd',
        dark: '#323232',
        med: '#9f9f9f',
        light: '#eeeeee',
        white: '#ffffff',
        warmpri: '#009392',
        warmsec: '#82b8bd',
        warmcomp: '#d3a356',
        warmcont: '#de7c4c',
        oldprim: '#14bda7',
        oldsec: '#8ad2c0',
    },
    font: {
      sans: 'Asap, sans-serif',
      serif: 'Arvo, sans',
      monospace: '"Ubuntu Mono", monospace',
    },
    fontSize: {
        xs: '0.75rem',
        sm: '0.875rem',
        base: '1rem',
        md: '1.25rem',
        lg: '1.75rem',
        xl: '3rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4rem',
    },
    fontWeight: {
        hairline: '100',
        thin: '200',
        light: '300',
        normal: '400',
        medium: '500',
        semibold: '600',
        bold: '700',
        extrabold: '800',
        black: '900',
    },
}

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    display: inline;
    font-family: ${({ theme }) => theme.font.sans};
  }
`

export { theme, GlobalStyle };