import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { 
  LeadP,
  P
} from "./elements"

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  margin: auto;
  width: 90vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.2);
  padding: 20px;
  margin-top: 2vh;
  margin-bottom: 1vh;
  border-radius: 15px;
  @media screen and (min-width: 992px) {
    width: 30vw;
    max-height: 70vh;
  }
  @media screen and (min-width: 500px) and (max-width: 992px) {
    max-width: 350px
  }
`

const LinkWrapper = styled(props => <AnchorLink {...props} />)`
  text-decoration: none;
`

const LinkBtn = styled.button`
  background-color: ${({ theme }) => theme.palette.warmcont};
  border: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => theme.fontSize.sm};
  min-width: 50%;
  height: 3rem;

  @media screen and (max-width: 993px) {
    width: 50%;
  }

  &:hover{
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.warmcont};
    border: ${({ theme }) => theme.palette.warmcont} 1px solid;
  }
`

const Card = ({ heading, queryRef, text, link }) => {
    const data = useStaticQuery(getImages)
    const image = data[queryRef]

    return(
        <CardContainer>
            <Img
                fluid={image.childImageSharp.fluid}
                style={{ margin: '1rem', maxHeight: '150px'}}
                imgStyle={{ objectFit: 'contain' }}
            />
            <LeadP small>{ heading }</LeadP>
            <P style={{paddingTop: "1em", paddingBottom: "2em"}} small>{ text }</P>
            <LinkWrapper to={ link }>
              <LinkBtn><strong>More Details</strong></LinkBtn>
            </LinkWrapper>
        </CardContainer>
    )
}

export const getImages = graphql`
query {
  fixed: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fixed(width: 500) {
        ...GatsbyImageSharpFixed_tracedSVG
      }
    }
  },
  fluid: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  analytics: file(relativePath: { eq: "analytics.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  team: file(relativePath: { eq: "team.jpg" }) {
    childImageSharp  {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  physio: file(relativePath: { eq: "physio-patient.jpg" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  prototype: file(relativePath: { eq: "prototype.jpg" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  ideation: file(relativePath: { eq: "ideation.jpg" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  ideationService: file(relativePath: { eq: "ideation.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  protoService: file(relativePath: { eq: "prototype.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  },
  notebook: allJupyterNotebook {
    edges {
      node {
        fileAbsolutePath
        html
      }
    }
  }
}
`

export { Card }