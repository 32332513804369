import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { useForm } from "react-hook-form"
import { API_PATH } from "../../data/constants"

const Form = styled.form`
    width: 100%;
    display: block;
    padding: 10px;
    font-family: ${({ theme }) => theme.font.sans};
`

const Label = styled.label`
    width: 90%;
    color: ${({ theme }) => theme.palette.light};
    font-family: ${({ theme }) => theme.font.sans};
`

const InfoLabel = styled.p`
    width: 90%;
    color: ${({ theme }) => theme.palette.light};
    font-family: ${({ theme }) => theme.font.sans};
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-top: 0;
    margin-bottom: 0.5em;
`

const Input = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    border-radius: 4px;
    font-family: ${({ theme }) => theme.font.sans};
    &:focus {
        background-color: ${({ theme }) => theme.palette.light};
        outline: 2px solid ${({ theme }) => theme.palette.warmcont};
    }
    &[type='button'] {
        background-color: ${({ theme }) => theme.palette.warmcont};
        border: none;
        padding: 16px 32px;
        text-decoration: none;
        margin: 4px 2px;
        cursor: pointer;
        width: auto;

        &:focus {
            outline: none;
        }
    }
`

const A = styled.a`
    font-family: ${({ theme }) => theme.font.sans};
    color: ${({ theme }) => theme.palette.light};
    font-size: ${({ theme }) => theme.fontSize.sm};
`

const TextArea = styled.textarea`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    border-radius: 4px;
    resize: none;
    font-family: ${({ theme }) => theme.font.sans};
    &:focus {
        background-color: ${({ theme }) => theme.palette.light};
        outline: 2px solid ${({ theme }) => theme.palette.warmcont};
    }
`

const Button = styled.button`
    background-color: ${({ theme }) => theme.palette.warmcont};
    border: none;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    width: auto;
    border-radius: 4px;
    font-family: ${({ theme }) => theme.font.sans};
    &:focus {
        outline: none;
    }
`


export default function ContactForm () {
    const { handleSubmit, register, errors, reset } = useForm()
    const [submitted, setSubmitted] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState(false)

    const onSubmit = data => {
        setSubmitting(true)
        setSubmitError(false)
        axios.post(`${ API_PATH }/contact-form`, data)
          .then(res => {
            setSubmitting(false)
            setSubmitted(true)
            reset()
          })
          .catch(res => {
            setSubmitError(true)
            setSubmitting(false)
          })
    }

    return (
        <>
        {
            submitError &&
            <>
                <InfoLabel>
                    There was an error with your submission. 
                    Please try again or send an email to &nbsp;
                    <A 
                     href="contact@equalneohealth.com">
                        contact@equalneohealth.com
                    </A>
                </InfoLabel>
            </>
        }
        {
            submitting &&
            <>
                <InfoLabel>Submitting your form, please wait....</InfoLabel>
            </>
        }
        {
            submitted && !submitting &&
            <>
                <InfoLabel>Thank you for your enquiry. We will be in touch shortly.</InfoLabel>
                <Button onClick={() => {setSubmitted(false)}}>Additional Enquiry</Button>
            </>
        }
        {
            !submitted && !submitting &&
            <Form onSubmit={ handleSubmit(onSubmit) } onReset={ reset }>
            
                <Label>
                    Name
                    <Input 
                        type="text" 
                        name="name"
                        ref={register({
                            required: "Required",
                            minLength: 1,
                            message: "Name is required"
                        })}/>
                </Label>
                <InfoLabel>{errors.name && errors.name.message}</InfoLabel>
                <Label>
                    Email
                    <Input 
                        type="email"  
                        name="email"
                        ref={register({
                            required: "Required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address"
                            }
                        })} />
                </Label>
                <InfoLabel>{errors.email && errors.email.message}</InfoLabel>
                <Label>
                    Subject
                    <Input 
                        type="text" 
                        name="subject"
                        ref={register({
                            required: "Required",
                            minLength: 1,
                            message: "Please enter a subject"
                        })} />
                    <InfoLabel>{errors.subject && errors.subject.message}</InfoLabel>
                </Label>
                <Label>
                    Message
                    <TextArea 
                        name="message" 
                        ref={register({
                            required: "Required",
                            minLength: 1,
                            message: "Please enter a message"
                        })} 
                        rows="5" />
                        <InfoLabel>{errors.message && errors.message.message}</InfoLabel>
                </Label>
                <Button type="submit">Send</Button>
                <Button type="reset">Clear</Button>
            </Form>
        }
        </>
    )
}
