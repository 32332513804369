import React, { useLayoutEffect, useState }  from "react"
import { theme, GlobalStyle } from "../theme/global-style"
import { ThemeProvider } from 'styled-components'
import { Helmet } from "react-helmet"
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function Layout({ children }) {
    const [width, height] = useWindowSize();

    return (
        <React.Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Equal Neohealth</title>
            <link rel="canonical" href="http://equalneohealth.com/" />
          </Helmet>
          <ThemeProvider theme={ theme }>
            <GlobalStyle />
            <Navbar width={ width } height={ height }/>
              { children }
            <Footer />
          </ThemeProvider>
        </React.Fragment>
      )
}